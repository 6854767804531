/*
 *
 * COHESION CONSTANTS
 *
 */

/*
 * TAGULAR
 */
export const WRITE_KEY = process.env.environment === 'production' ? 'wk_1NT298Z1fu0BKyOCPNAXHDkZ9OP' : 'wk_24yH5wA4KRm7uk2m6DWo37rMSik';
export const SOURCE_KEY = process.env.environment === 'production' ? 'src_1NT297rret2SKU4MnEHx4eIxk2A' : 'src_24yH5vGUPks7O2KRIGUNCHZrR19';

/*
 * MONARCH
 */
export const MONARCH_SOURCE = 'a2d53376-ebcd-4dcd-a53c-0b4c55fdef06';
export const MONARCH_TOKEN = 'e3589a83-d2c8-41c8-bcb0-f2093bb2b757';

// Request test ruleset for local Cypress tests so we don't impact the model
export const CDAPI_TEST_RULESET = 'cDAPITestRuleset';
export const BAU_RULESET = 'landingPageRules';
