export const getFiberProducts = ( products ) => (
  {
    fiber200: products.filter( ( product ) => product.hash === 'fiber-200m' )[ 0 ],
    fiber500: products.filter( ( product ) => product.hash === 'fiber-500m' )[ 0 ],
    fiberGig: products.filter( ( product ) => product.hash === 'fiber-gig' )[ 0 ],
    fiber2Gig: products.filter( ( product ) => product.hash === 'fiber-2-gig' )[ 0 ],
    fiber5Gig: products.filter( ( product ) => product.hash === 'fiber-5-gig' )[ 0 ],
    fiber7Gig: products.filter( ( product ) => product.hash === 'fiber-7-gig' )[ 0 ],
    copper: products.filter( ( product ) => product.hash === 'copper' )[ 0 ]
  }
);

export const getFeaturedProduct = ( products, featuredHash ) => products.filter( ( product ) => product.hash === featuredHash )[ 0 ];

export const defaultProducts = {
  'fiber-200m': {
    title: 'Fiber 200',
    name: 'Fiber 200',
    hash: 'fiber-200m',
    price: {
      base: '$39.99',
      promo: '$29.99'
    },
    legal: {
      proximity: 'per month with bank Auto Pay mo. 1-6, then $49.99 mo. 7-12',
      tooltip: 'ag-legal-500m',
      short: 'per month with bank Auto Pay mo. 1-6, then $49.99 mo. 7-12',
      hash: 'new-residential-only-200'
    }
  },
  'fiber-500m': {
    title: 'Fiber 500',
    name: 'Fiber 500',
    hash: 'fiber-500m',
    price: {
      base: '$54.99',
      promo: '$44.99'
    },
    legal: {
      proximity: 'per month with bank Auto Pay, for 12 months',
      tooltip: 'ag-legal-500m',
      short: 'per month with bank Auto Pay, for 12 months',
      hash: 'new-residential-only-500'
    }
  },
  'fiber-gig': {
    title: 'Fiber Gig',
    name: 'Fiber 1 Gig',
    hash: 'fiber-gig',
    price: {
      base: '$74.99',
      promo: '$64.99'
    },
    legal: {
      proximity: 'per month with bank Auto Pay, for 12 months',
      tooltip: 'ag-legal-gig',
      short: 'per month with bank Auto Pay, for 12 months',
      hash: 'Gig-new-residential'
    }
  },
  'fiber-2-gig': {
    title: 'Fiber 2 Gig',
    name: 'Fiber 2 Gig',
    hash: 'fiber-2-gig',
    price: {
      base: '$109.99',
      promo: '$99.99'
    },
    legal: {
      proximity: 'per month with bank Auto Pay',
      short: 'per month with bank Auto Pay',
      tooltip: 'ag-legal-2gig',
      hash: '2g-service'
    }
  },
  'fiber-5-gig': {
    title: 'Fiber 5 Gig',
    name: 'Fiber 5 Gig',
    hash: 'fiber-5-gig',
    price: {
      base: '$139.99',
      promo: '$129.99'
    },
    legal: {
      proximity: 'per month with bank Auto Pay, for 12 months',
      tooltip: 'ag-legal-5gig',
      short: 'per month with bank Auto Pay, for 12 months',
      hash: '5g-service'
    }
  },
  'fiber-7-gig': {
    title: 'Fiber 7 Gig',
    name: 'Fiber 7 Gig',
    hash: 'fiber-7-gig',
    price: {
      base: '$309.99',
      promo: '$299.99'
    },
    legal: {
      proximity: 'per month with Auto Pay, for 12 months',
      tooltip: 'auto-pay',
      short: 'per month with Auto Pay, for 12 months',
      hash: '7g-service'
    }
  }

};

export default defaultProducts;
